.wrapper{
    position: absolute;
    height: 100vh;
    background-color: white;
    top: 0;
    z-index: 1000;
    width: 100%;
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    border-bottom: 1px solid #efefef;
    background-color: #1e94c3f5;
    padding: 0rem 1rem;
}
.closeBtn{
    background: transparent;
    border: 1px solid #e4e4e4;
    cursor: pointer;
    padding: 0.5rem 1rem;
}
.contentWrapper{
    padding: 2rem 0rem;
}
.menu{
    padding: 1rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid #e1e1e1;
}
.menu:nth-child(1){
    border-top: 1px solid #e1e1e1;
}
.menu > .label{
    font-size: 1.3rem;
    font-weight: 600;
    text-decoration: none;
    color: #000;
}
.burgerMenuWrp{
    font-size: 1.5rem;
}