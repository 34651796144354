body {
  margin: 0;
  width: 100%;
    font-family: "Roboto Slab", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-card-head-title {
   color: #fff;
   font-size: 1.5rem;
}
.ant-card-head{
  background-color: #1e94c3f5 !important;
}
