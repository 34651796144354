.wrapper {
    position: relative;
}

.contactwrapper {
    background-image: url("../../Assests/carousel-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(50vh - 60px);
}

.contactUs {
    height: calc(50vh - 60px);
    z-index: 101;
    color: #fff;
    position: relative;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 4rem;
}

.overlay {
    position: absolute;
    background-color: #000000a6;
    top: 0;
    width: 100%;
    height: calc(50vh - 60px);
    z-index: 100;
}

.left {
    width: 650px;
    height: 400px;
}

.left>img {
    width: 100%;
    height: 100%;
}

.name_email {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.btn {
    background-color: #1e94c3f5;
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 0.3rem;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
}

.contact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
    margin: 2rem;
}

.row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

.right>h2 {
    font-size: 2rem;

}

.mapWrp {
    border-radius: 1rem;
    width: 100%;
    height: 100%;
}

.contactCardWrap {
    background-color: #1e94c3f5;
    color: #fff;
}

.contactCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 330px;
    padding: 2rem;

}

.contactdetails {
    display: grid;
    grid-gap: 1rem;
    font-size: 1.4rem;
    text-align: justify;
}

.contactusdetail {
    display: flex;
    gap: 2rem;
}

.contactusdetail>.icon {
    display: flex;
    align-items: center;
}

.contactusdetail>.text {
    line-height: 1.5rem;
}

.companyname>h2 {
    font-size: 2.5rem;
}

.socialmedia {
    margin-top: 1.5rem;
    margin-right: auto;
}

.socialmedia>.footer_social_media {
    display: flex;
    gap: 1rem;
    /* margin-top: 1rem; */
    font-size: 1.5rem;
}

@media screen and (max-width: 1200px) {
    .contact {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 768px) {
    .contact {
        grid-template-columns: 1fr;
        margin: 0;
    }

    .left {
        width: 100%;
        height: 100%;
    }

    .row {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .contactCard {
        justify-content: flex-start;
    }

    .map {
        height: 400px;
        margin-top: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .contact {
        grid-template-columns: 1fr;
    }

}