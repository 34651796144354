.wrapper {
    position: relative;
}

.aboutwrp {
    background-image: url("./carousel-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(50vh - 60px);
}
.about {
    height: calc(50vh - 60px);
    z-index: 101;
    color: #fff;
    position: relative;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 4rem;

}

.overlay {
    position: absolute;
    background-color: #000000a6;
    top: 0;
    width: 100%;
    height: calc(50vh - 60px);
    z-index: 100;

}
.allproduct{
    padding: 2rem;
}
.producthead{
    text-align: center;
    font-size: 2rem;
}
.productWrapper{
    padding: 2rem;
    background-color: #efefef;
}
.productsWrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 2rem;
     
}

.products{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    overflow: hidden;
    margin-bottom:1rem;
}
.productImage{
    height: 220px;
    width: 100%;
}
.productImage >img{
    width: 100%;
    height: 100%;
}
.acdetail{
    padding: 0.5rem;
    height: 40px;
}
.acdetail > .productName{
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: capitalize;
    color: #303030;
}
.heading{
    font-size: 2.3rem;
    font-weight: 600;
    margin: 2rem  0;
    text-align: center;
}

@media screen and (max-width:768px){
    .productsWrapper{
        grid-template-columns:1fr;
        grid-gap: 1rem;
         
    }
}