.wrapper {
    position: relative;
    overflow: hidden;
}

.aboutwrp {
    background-image: url("./carousel-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(50vh - 60px);
}

.about {
    height: calc(50vh - 60px);
    z-index: 101;
    color: #fff;
    position: relative;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 4rem;

}

.overlay {
    position: absolute;
    background-color: #000000a6;
    top: 0;
    width: 100%;
    height: calc(50vh - 60px);
    z-index: 100;

}

.secondWrapper {
    margin: 2rem;

}

.mainHeading{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
}
.mainHeading > h1{
    font-size: 2.5rem;
    padding: 2rem;
}
.servicewrapper{
    margin: 2rem;
    padding: 2rem;
}
.reverse{
    flex-direction: row-reverse;
}
.serviceimg{
    width:550px;
    height: 300px;
    max-width: 450px;
    border-radius: 0.3rem;
    overflow: hidden;
}
.serviceimg >img{
    width: 100%;
    height: 100%;
}
.ceodetail{
    display:flex;

}

.aboutCeo{
    width: 85%;
    margin-right: 2rem;
    font-size: 1.1rem;
    text-align: justify;
}
.lead{
    display: flex;
    align-items: center;
    /* margin-left: 1rem; */
}
.team{
    width: 10rem;
    height: 9rem;
    border-radius: 0.3rem;
    overflow: hidden;
}
.team > img{
    width: 100%;
    height: 100%;
}
.ant-card-head{
    background-color: aqua !important;
  }
.flex{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3rem;
}
 .leftMenu{
    /* width: 60%; */
    text-align: justify;
    font-size: 1.5rem;
}
.title{
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.8rem;
}
.description{
    font-size: 1.2rem;
    line-height: 30px;
}
.flex > .imageWrapper{
    width: 40%;
}
.coreteam{
    padding: 2rem;
}

@media screen  and (max-width :768px){
    .secondWrapper{
        margin: 0;
    }
    .ceodetail{
      flex-direction: column;
    }
    .flex{
        flex-direction: column;
    }
    .flex > .leftMenu{
        width: 100%;
        
    }
    .serviceimg{
        width: 100%;
        margin-top: 1.5rem;
    }
    .servicewrapper{
        margin: 0;
    }
    .mainHeading > h1{
       padding: 0;
       font-size: 1.5rem;
    }
    .aboutCeo{
        width: 100%;
        margin: 0px;
    }
    .mainHeading > h1{
        text-align: start;
        
    }
}