.wrapper{
    overflow: hidden;
}
.topSection{
    background-image: url("./carousel-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    font-size:larger;
}
 h1{
    text-align: center;
    margin: 0;
    font-size: 4.5rem;
}
.topSection > .banner > h2{
    font-size: 1.5rem;
    text-align: center;
}
.topSection > .banner{
    z-index: 101;
    color: #fff;
    position: relative;
    padding: 2rem;
    width: 80%;
    margin: 0 auto;
}
.topSection > .banner > p{
    text-align: justify;
    line-height: 27px;
    font-size: large;

}
.firstWrapper{
    position: relative;
}
.overlay{
    position: absolute;
    background-color: #000000a6;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

}
.companytopSection{
    background-image: url("./carousel-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:40vh;
    display: flex;
    align-items: center;
    font-size:larger;
}
.companytopSection > .banner{
    z-index: 101;
    color: #fff;
    position: relative;
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
}
.companyDetail{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 4rem;
    font-size: 1.2rem;
   
}
.companyfirstWrapper{
    position: relative;
}
.detail>h2{
    margin: 0;
   font-size: 3rem;
}
.heading{
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
}
.productWrapper{
    padding: 2rem;
    background-color: #efefef;
}
.productsWrapper{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 1.5rem;
    margin: 1rem 0;
}

.products{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    overflow: hidden
}
.productImage{
    height: 220px;
    width: 100%;
}
.productImage >img{
    width: 100%;
    height: 100%;
}
.acdetail{
    padding: 0.5rem;
    height: 40px;
}
.acdetail > .productName{
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: capitalize;
    color: #303030;
}
.productdetail{
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-align: justify;
}
.mainHeading{
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
    /* margin: 4rem; */
    margin: 4rem 0 2rem 0;
}
.marqueLogo{
    width: 200px;
    height: 200px;
    margin-right: 1rem;
    overflow: hidden;
}
.marqueLogo > img{
    width: 100%;
    height: 100%;
}
.leadingBrandsWrp{
    padding: 2rem;
    min-height: 40vh;
    /* margin: 2rem 0; */
}
.servicewrapper{
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1.5rem;
}
.service{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
}
.serviceimg{
    width: 100%;
    height: 270px;
}
.serviceimg >img{
    width: 100%;
    height: 100%;
}
.allservice{
    height: 70px;
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: capitalize;
    color: #303030;
    display: flex;
    align-items: center;
    /* padding: 1rem; */
    gap: 1rem;
}
.servicesicons{
    width: 70px;
    height: 70px;
    background-color:#1e94c3f5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.whyChooseUsWrp{
    /* width: 100%; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin: 4rem 0 2rem 0;
}

.row{
    display: grid;
    grid-template-columns: 90px 1fr;
    margin-bottom: 1rem;
}
.row > .leftSec{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #1e94c3f5;
    margin-right: 0.5rem;
}
.iconImgWrp{
    padding: 0.5rem;
}
.iconImgWrp > img{
    width: 100%;
    height: 100%;
}
.rightSec > .smHead{
    font-size: 1.4rem;
    margin-bottom: 0.3rem;
}
.rightSec > .para{
    color: #505050;
}
.leftMenu > .head{
    color: #000;
    font-size: 2rem;
    margin-bottom: 0.5rem;
}
.leftMenu > p{
    color: #505050;
    font-size: 1.1rem;
    line-height: 30px;
}
.whyCus{
    padding: 2rem;
    background-color: #efefef;
}
.imgWrp{
    width: 600px;
    height: 400px;
}
.imgWrp > img{
    width: 100%;
    height: 100%;
}
.testimonilesWrp{
    margin-top: 3rem;
    padding: 2rem;
    min-height: 50vh;
}
.domesticProductsWrp{
    margin-bottom: 3rem;
}
.viewproductbtn{
    background-color: #1e94c3f5;
    border: none;
    padding: 1rem 3rem;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 0.5rem;
    color: #fff;
}

.service{
    background-color: #efefef;
    padding: 2rem 2rem 10rem 2rem;

}
.mainHeading{
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
    /* margin: 4rem; */
    margin: 4rem 0 2rem 0;
}
.subwrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);

}
.serviceImg{
    width: 700px;
    height: 450px;
}

.serviceImg >img{
    width: 100%;
    height: 100%;
}
.card >p{
    font-size: 1.2rem;
    margin: 0.8rem 0;
}
.card >h2{
    margin: 0;
}
.abtus{
    background-color: #1e94c3f5;
    border: none;
    padding: 1rem 3rem;
    font-size: 1.2rem;
    cursor: pointer;
    color: #fff;
    margin-top: 0.5rem;
    /* margin: 1rem ; */
}
.count{
    font-size: 2.5rem;
    font-weight: 600;
}
.countlabel{
    font-size: 1.2rem;
}

@media screen  and (max-width:1200px){
    .subwrapper{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen  and (max-width:768px){
    .subwrapper{
        grid-template-columns:1fr;
        grid-gap: 1.5rem;
    }
    .serviceImg{
        width: 100%;
        height: 100%;
    }
    
    .serviceImg >img{
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .productsWrapper{
        grid-template-columns: repeat(3,1fr);
    }

}
@media screen and (max-width: 768px) {
    .productsWrapper{
        grid-template-columns: repeat(2,1fr);
    }
    
    .whyChooseUsWrp{
        grid-template-columns: 1fr !important;
    }
    .imgWrp{

        width: 100%;
        height: 100%;
    }
    
    .imgWrp > img {
        width: 100%;
        height: 100%;
    }
    .topSection > .banner{
        z-index: 101;
        color: #fff;
        position: relative;
        padding: 2rem;
        width: 100%;
    }
    .topSection > .banner > h1{
        font-size: 2.5rem;
    }
    .topSection > .banner > h2{
        font-size: 1.1rem;
    }
    .topSection > .banner > p{
        font-size: 1rem;
    }
.companyDetail{
    grid-template-columns: repeat(2,1fr);
    grid-gap: 3rem;
}
.topSection{
    height: 450px;
}
   
}
.wrokWrapper{
    min-height: 20vh;
    padding: 2rem;
}
.work{
    margin-right: 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    background-color: #fff;
}
.workImgWrp{
    width: 100%;
    height: 300px !important;
}
.description{
    padding: 0.2rem 0.5rem;
    background-color: #f2f2f2;
}
.workImgWrp > img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 480px) {
    .productsWrapper{
        grid-template-columns: 1fr;
    }
    
    
}
