.wrapper{
    /* margin: 2rem; */
    position: relative;
}
.mainHeading{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
}
.mainHeading > h1{
    font-size: 2.5rem;
    padding: 2rem;
}
.servicetwrp{
    background-image: url("./carousel-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(50vh - 60px);
}

.service{
    height: calc(50vh - 60px);
    z-index: 101;
    color: #fff;
    position: relative;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 4rem;
   
}
.overlay{
    position: absolute;
    background-color: #000000a6;
    top: 0;
    width: 100%;
    height: calc(50vh - 60px);
    z-index: 100;

}
.servicewrapper{
    margin: 2rem;
    padding: 2rem;
}

.reverse{
    flex-direction: row-reverse;
}
.serviceimg{
    width:450px;
    height: 270px;
    max-width: 350px;
}
.serviceimg >img{
    width: 100%;
    height: 100%;
}


.flex{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
}
.flex > .leftMenu{
    width: 50%;
}
.flex > .imageWrapper{
    width: 50%;
}
@media screen and (max-width: 768px) {
    .flex{
        flex-direction: column;
    }
    .flex > .leftMenu{
        width: 100%;
    }
    .flex > .imageWrapper{
        width: 100%;
    }  
    .serviceimg{
        width: auto;
        height: auto;
        margin-top: 1rem ;
        max-width: 100%;
    } 
    .servicewrapper{
        margin: 20px;
        padding: 0px;
    }
    .mainHeading{
        margin: 2rem 0.8rem;
        font-size: 0.9rem;
        line-height: 40px;
    }
}
.title{
    font-size:1.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}
.description{
    text-align: justify;
    line-height: 1.8rem;
}