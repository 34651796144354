.wrapper{
    height: 70px;
    background-color:#1e94c3f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 2rem;
}
.rightMenu{
    display: flex;
    align-items: center;
}
.rightMenu > .menu{
    margin-right: 0.5rem;
}
.rightMenu > .menu > .menuItem{
    text-decoration: none;
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
    margin-right: 1rem;
    font-weight: 600;
}
.logo{
  color: #000;
  text-decoration: none;
}
.burgerMenu{
  border: 1px solid #fff;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  background-color: #fff;
}
.adpLogo{
  height: 50px;
  width: 70px;
}
.adpLogo > img{
  width: 100%;
  height: 100%;
}