.form_field > input{
    padding: 1.3rem;
    width: 100%;
    border-radius: 0.3rem;
    border: 1px solid #5050504d;
    outline: none;
    margin-bottom: 1.2rem;
}
.form_field{
    width: 100%;
    display: flex;
}