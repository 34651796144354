.footerWrapper {
    /* margin-left: 4rem; */
    padding: 4rem;
    bottom: 0;
    background-color:#1e94c3f5;
    color: #fff;
  }
  .footer_card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .footer_logo {
    height: 5rem;
    width: 5rem;
  }
  
  .footer_2 {
    display: grid;
    place-content: center;
    line-height: 2rem;
  }
  .footer_2_link {
    color:#fff;
    text-decoration: none;
    text-align: left;
    font-weight: 600;
  }
  .footer_3 > h2 {
    margin: 0;
  }
  .footer_3 > .footer_contact_info {
    line-height: 2rem;
    margin-top: 1rem;
    font-weight: 600;
  }
  .footer_3 > .footer_social_media {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  
  .copy_rights {
    margin-top: 3rem;
    text-align: center;
    font-weight: 600;
    word-spacing: 0.2rem;
  }
  
  .footer_2 .footer_2_link:hover {
    color: aliceblue;
  }

  .companyLogo{
    height: 80px;
    width: 80px;
  }
  .companyLogo > img{
    width: 100%;
    height: 100%;
  }
  
  .iconWrp{
    width: 35px;
    height: 35px;
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    cursor: pointer;
  }
  .linkdin{
    border: 1px solid #007bb6;
    background-color: #007bb6;
  }
  .insta{
    border: 1px solid #E4405F;
    background-color: #E4405F;
    font-size: 1.7rem;
  }
  .youtube{
    border: 1px solid #CD201F;
    background-color: #CD201F;
  }
  .whatsapp{
    border: 1px solid #25D366;
    background-color: #25D366;
  }

  @media only screen and (max-width: 600px) {
    .footerWrapper {
      padding: 2rem;
    }
    .footer_card {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: start;
      grid-gap: 2rem;
    }
  }
  